<template>
  <div>
    <base-module :moduleOptions="options" title="Tipo de procesos" ref="module">
    </base-module>
    <process-type-form
      :handlePostSubmit="handlePostSubmit"
      :record="selectedRow"
    />
  </div>
</template>

<script>
import ProcessTypeForm from "./ProcessTypeForm";
import BaseModule from "@/components/Base/BaseModule";
import { customComponentActions } from "@/mixins/actions.js";

export default {
  inject: ["processTypeRepository", "citiesRepository"],

  mixins: [customComponentActions],

  components: {
    BaseModule,
    ProcessTypeForm
  },

  data() {
    return {
      options: {
        columns: [
          {
            header: "Nombre",
            prop: "name"
          }
        ],
        repository: {
          name: "processTypeRepository"
        },
        sortable: ["name"],
        primary: "id"
      },

      selectedRow: null
    };
  },

  methods: {
    handlePostSubmit() {
      this.$refs.module.handleLoadData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
