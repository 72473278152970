<template>
  <div>
    <base-module :moduleOptions="options" title="Conceptos" ref="module">
    </base-module>
    <concept-form :handlePostSubmit="handlePostSubmit" :record="selectedRow" />
  </div>
</template>
<script>
import BaseModule from "@/components/Base/BaseModule";
import ConceptForm from "./ConceptForm";
import { customComponentActions } from "@/mixins/actions.js";
export default {
  name: "Concepts",
  inject: ["conceptsRepository"],

  mixins: [customComponentActions],

  components: {
    BaseModule,
    ConceptForm
  },

  data() {
    const me = this;
    return {
      options: {
        columns: [
          {
            header: "Concepto",
            prop: "concept"
          },
          {
            header: "Valor",
            prop: "value"
          },
          {
            header: "Año",
            prop: "year"
          }
        ],
        repository: {
          name: "conceptsRepository"
        },
        primary: "id",
        templates: {
          value(h, row) {
            return row.value
              ? `${me.$options.filters.numericFormat(row.value)}`
              : "";
          },
          year(h, row) {
            return row.value ? row.year : "";
          }
        }
      },

      selectedRow: null
    };
  },

  methods: {
    handlePostSubmit() {
      this.$refs.module.handleLoadData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
