<template>
  <base-modal-form
    title="Crear"
    ref="modalform"
    @postSubmit="handlePostSubmit"
    @show="handleShow"
  >
    <template v-slot:formfields>
      <input type="hidden" name="id" v-model="formFields.id" />
      <div class="flex space-x-4">
        <b-form-group
          label="Nombres"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.name)"
        >
          <b-form-input
            name="name"
            v-model.trim="$v.formFields.name.$model"
            :state="validateState('name')"
          >
          </b-form-input>
        </b-form-group>
      </div>
    </template>
  </base-modal-form>
</template>

<script>
import BaseModalForm from "@/components/Base/BaseModalForm";
import { required, minLength, email } from "vuelidate/lib/validators";
import { customComponentActions } from "@/mixins/actions.js";
import { deserialize } from "jsonapi-fractal";

export default {
  props: ["handlePostSubmit", "record"],
  mixins: [customComponentActions],
  inject: ["processTypeRepository", "citiesRepository"],

  components: {
    BaseModalForm
  },

  data() {
    return {
      formFields: {
        id: null,
        name: ""
      },

      repository: "processTypeRepository",
      primary: "id",
      isString: ["phone"],

      cities: []
    };
  },

  validations: {
    formFields: {
      name: {
        required,
        minLength: minLength(3)
      }
    }
  },

  methods: {
    handleShow() {
      const me = this;

      if (me.record) {
        me.formFields = { ...me.record };
      }
    }
  },

  async mounted() {
    const me = this;

    let cities = await me.citiesRepository.get();

    me.cities = [
      {
        value: null,
        text: "Seleccione ciudad"
      },
      ...deserialize(cities.data).map(city => {
        return {
          value: city.id,
          text: city.name
        };
      })
    ];
  }
};
</script>

<style lang="scss" scoped></style>
