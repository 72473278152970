<template>
  <base-modal-form
    title="Crear"
    ref="modalform"
    @postSubmit="handlePostSubmit"
    @show="handleShow"
  >
    <template v-slot:formfields>
      <input type="hidden" name="id" v-model="formFields.id" />
      <div class="flex space-x-4">
        <b-form-group
          label="Concepto"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.concept)"
        >
          <b-form-input
            name="concept"
            v-model.trim="$v.formFields.concept.$model"
            :state="validateState('concept')"
            readonly
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Valor"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.value)"
        >
          <b-form-input
            name="value"
            v-model.trim="$v.formFields.value.$model"
            :state="validateState('value')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Año"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.year)"
        >
          <b-form-input
            name="year"
            v-model.trim="$v.formFields.year.$model"
            :state="validateState('year')"
          >
          </b-form-input>
        </b-form-group>
      </div>
    </template>
  </base-modal-form>
</template>

<script>
import BaseModalForm from "@/components/Base/BaseModalForm";
import { required, numeric } from "vuelidate/lib/validators";
import { customComponentActions } from "@/mixins/actions.js";

export default {
  props: ["handlePostSubmit", "record"],
  mixins: [customComponentActions],
  inject: ["conceptsRepository"],

  components: {
    BaseModalForm
  },

  data() {
    return {
      formFields: {
        id: null,
        concept: "",
        value: 0,
        year: new Date().getFullYear(),
        type: ""
      },

      repository: "conceptsRepository",
      primary: "id"
    };
  },

  validations: {
    formFields: {
      concept: {
        required
      },
      value: {
        required,
        numeric
      },
      year: {
        required,
        numeric
      }
    }
  },

  methods: {
    handleShow() {
      const me = this;

      if (me.record) {
        me.formFields = { ...me.record };
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
